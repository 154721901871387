import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import Footer_logo from "../../Assets/logo.png";

export const Footer = ({ showBothDivs }) => {
  return (
    <div className="Footer-main">
      {showBothDivs ? (
        <>
          <div className="content-footer">
            <div className="footer-logo">
              <img src={Footer_logo} alt="logo" />
            </div>

            <div className="footer-nav">
              <ul className="footer-nav-menu">
                <NavLink to="/" className="footer-navline1">HOME</NavLink>
                <NavLink to="/men" className="footer-navline1">MEN</NavLink>
                <NavLink to="/women" className="footer-navline2">WOMEN</NavLink>
                <NavLink to="/productlist" className="footer-navline1">PRODUCTS</NavLink>
              </ul>
            </div>

            <div className="footer-social-media">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
              <FontAwesomeIcon icon={faInstagram} size="2x" />
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </div>

            <div className="end-footer">
              <p>&copy; 2024 Tarunika. All Rights Reserved</p>
            </div>
          </div>
        </>
      ) : (
        <div className="content-footer">
          <div className="footer-logo">
            <img src={Footer_logo} alt="logo" />
          </div>

          <div className="footer-nav">
            <ul className="footer-nav-menu d-flex">
              <NavLink to="/" className="footer-navline1">HOME</NavLink>
              <NavLink to="/men" className="footer-navline1">MEN</NavLink>
              <NavLink to="/women" className="footer-navline2">WOMEN</NavLink>
              <NavLink to="/productlist" className="footer-navline1">PRODUCTS</NavLink>
            </ul>
          </div>

          <div className="footer-social-media">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
            <FontAwesomeIcon icon={faInstagram} size="2x" />
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </div>

          <div className="end-footer">
            <p>&copy; 2024 Tarunika. All Rights Reserved</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
