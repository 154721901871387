import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Layout from "./../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";
import AddAddressPopup from "./AddAddressPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../Styles/CheckoutPage.css";

const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [discount, setDiscount] = useState(location.state?.discount || 0); 
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressData, setAddressData] = useState([]);
  const [addressLoading, setAddressLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const defaultImage =
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg";

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(
          "http://tarunika.backendapihub.com/cart/show",
          {
            headers: {
              "access-token": "1",
            },
          }
        );
        if (response.status === 200 && Array.isArray(response.data.data)) {
          const items = response.data.data;
          const combinedItems = items.reduce((acc, item) => {
            const existingItemIndex = acc.findIndex(
              (i) =>
                i.product_id === item.product_id &&
                i.size === item.size &&
                i.price === item.price
            );
            if (existingItemIndex !== -1) {
              acc[existingItemIndex].qty += item.qty;
              acc[existingItemIndex].amount += item.amount;
            } else {
              acc.push({ ...item });
            }
            return acc;
          }, []);

          setCartItems(combinedItems);
        } else {
          setError("Failed to fetch cart items.");
        }
      } catch (error) {
        setError("Error fetching cart items.");
      }
    };

    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "http://tarunika.backendapihub.com/address/getall",
          {
            headers: {
              "access-token": "1",
            },
          }
        );
        if (response.status === 200 && response.data.status === "success") {
          const addresses = response.data.data || [];
          setAddressData(addresses);
        } else {
          setError("Failed to fetch addresses.");
        }
      } catch (error) {
        setError("Error fetching addresses.");
      } finally {
        setAddressLoading(false);
      }
    };

    fetchCartItems();
    fetchAddresses();
  }, []);

  useEffect(() => {
    localStorage.setItem("addresses", JSON.stringify(addressData));
  }, [addressData]);

  const calculateSubtotal = () =>
    cartItems.reduce((acc, item) => acc + (item.amount || 0), 0);
  
  const calculateTotal = () => calculateSubtotal() - discount; 
  const handleCheckout = async () => {
    try {
      if (!selectedAddress) {
        toast.error("Please select a delivery address.");
        return;
      }

      const response = await axios.post(
        "http://tarunika.backendapihub.com/payments/razorpay/checkout",
        {
          shippingCharges: 0,
          addressID: selectedAddress.id,
          couponDiscount: discount,
        },
        {
          headers: {
            "access-token": "1",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        const paymentLink = response.data.data[0].payment_link;
        window.location.href = paymentLink;
        toast.success("Redirecting to payment...");
      } else {
        toast.error("Failed to create payment link.");
      }
    } catch (error) {
      toast.error("Error during checkout.");
    }
  };

  const handleDeleteAddress = (addressId, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this address?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `http://tarunika.backendapihub.com/address/delete/${addressId}`,
                {
                  headers: {
                    accept: "application/json",
                    "access-token": "1",
                  },
                }
              );

              if (
                response.status === 200 &&
                response.data.status === "success"
              ) {
                const updatedAddresses = addressData.filter(
                  (_, i) => i !== index
                );
                setAddressData(updatedAddresses);
                toast.success("Address deleted successfully.");
              } else {
                toast.error("Failed to delete address.");
              }
            } catch (error) {
              toast.error("Error deleting address.");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAddAddress = (newAddress) => {
    setAddressData((prevAddresses) => [...prevAddresses, newAddress]);
    setIsPopupOpen(false);
    window.location.reload();
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="checkout-page">
        <div className="checkout-card">
          <div className="shipping-address">
            <div className="shipping-address-header">
              <h2>Shipping Address</h2>
            </div>
            <div className="shipping-address-content">
              {addressLoading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  {addressData.length === 0 ? (
                    <p>No shipping addresses available</p>
                  ) : (
                    addressData.map((address) => (
                      <div key={address.id} className="address-card">
                        <p>
                          {address.address1}, {address.address2}, {address.city}
                          , {address.state} {address.pincode}
                        </p>
                        <button
                          className="delete-btn"
                          aria-label="Delete Address"
                          onClick={() =>
                            handleDeleteAddress(
                              address.id,
                              addressData.findIndex((a) => a.id === address.id)
                            )
                          }
                        >
                          <DeleteIcon /> Delete
                        </button>
                        <button
                          className="Deliver-btn"
                          onClick={() => handleSelectAddress(address)}
                        >
                          Deliver To This Address
                        </button>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>

            <button
              className="add-address-button"
              onClick={() => setIsPopupOpen(true)}
            >
              + Add New Address
            </button>
          </div>

          <div className="cart-summary">
            <h2>Order Summary</h2>
            <div className="order-summary">
              <p>
                <span>Subtotal:</span>{" "}
                <span>₹ {calculateSubtotal().toFixed(2)}</span>
              </p>
              <p>
                <span>Shipping:</span> <span>Free</span>
              </p>
              <p>
                <span>Coupon Discount:</span>{" "}
                <span>₹ {discount.toFixed(2)}</span>
              </p>
              <p className="total">
                <span>Total:</span> <span>₹ {calculateTotal().toFixed(2)}</span>
              </p>
            </div>

            {selectedAddress && (
              <div className="selected-address">
                <h2>Billing Address</h2>
                <p>
                  {selectedAddress.address1}, {selectedAddress.address2},{" "}
                  {selectedAddress.city}, {selectedAddress.state}{" "}
                  {selectedAddress.pincode}
                </p>
              </div>
            )}

            <button className="checkout-button" onClick={handleCheckout}>
              Continue With Payment
            </button>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
      <AddAddressPopup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onAddAddress={handleAddAddress}
      />
    </Layout>
  );
};

export default CheckoutPage;
