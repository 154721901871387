import React, { useState } from "react";
import './UserMenu.css'
import { NavLink, useNavigate } from "react-router-dom";

const UserMenu = () => {

  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  const handleOptionSelect = (option, path, event) => {
    event.preventDefault();
    console.log(`Selected Option: ${option}`);
    setSelectedOption(option);
    navigate(path);
  }

  const getButtonLabel = () => {
    return selectedOption? `My Account (${selectedOption})` : `My Account`;
  }


  return (
    <>
      <div className="myAccountBox">
        <h1 className="dashLeftTitle">My Account</h1>
        <div className="list-group list-group-flush">
          <NavLink to="/dashboard/user/profile" className="list-group-itemum list-group-item list-group-item-action ">Profile</NavLink>
          {/* <NavLink to="/dashboard/user/profile" id="menu-profile" className="list-group-itemum list-group-item list-group-item-action">Edit Information</NavLink> */}
          <NavLink to="/dashboard/user/orderhistory" id="menu-order" className="list-group-itemum list-group-item ist-group-item-action">Orders History</NavLink>
          <NavLink to="/dashboard/user/wishlist" id="menu-wish" className="list-group-itemum list-group-item list-group-item-action">Wishlist</NavLink>
          {/* <NavLink to="/dashboard/user/changepassword" id="menu-password" className="list-group-itemum list-group-item list-group-item-action">Change Password</NavLink> */}
        </div>
      </div>

      <div className="userMenubtnClass">

        <div class="dropdown drpUserbtn">
          <button class="btn btn-secondary dropdown-toggle drpUserbtnMyAct" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            My Account {selectedOption && `(${selectedOption})`}
            {getButtonLabel()}
          </button>
          <ul class="dropdown-menu usrMnbtn">
            <li><NavLink to="/dashboard/user/profile" className="dropdown-item" onClick={(event) => handleOptionSelect("Dashboard", "/dashboard/user/profile", event)}>Profile</NavLink></li>
            {/* <li><NavLink to="/dashboard/user/profile" className="dropdown-item" onClick={(event) => handleOptionSelect("Edit Information", "/dashboard/user/profile", event)}>Edit Information</NavLink></li> */}
            <li><NavLink to="/dashboard/user/orderhistory" className="dropdown-item" onClick={(event) => handleOptionSelect("Orders History", "/dashboard/user/orderhistory", event)}>Orders History</NavLink></li>
            <li><NavLink to="/dashboard/user/wishlist" className="dropdown-item" onClick={(event) => handleOptionSelect("Wishlist", "/dashboard/user/wishlist", event)}>Wishlist</NavLink></li>
            {/* <li><NavLink to="/dashboard/user/changepassword" className="dropdown-item" onClick={(event) => handleOptionSelect("Change Password", "/dashboard/user/changepassword", event)}>Change Password</NavLink></li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserMenu;