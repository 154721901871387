import React, { useState } from "react";
import Layout from '../Components/Layout/Layout'
import SubHome from '../Components/Sub_Home/SubHome'
import { useAuth } from '../Context/Auth'
import Footer from "../Components/Layout/Footer";

const HomePage = () => {
  const [auth,setAuth]= useAuth();
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true} > 
      <SubHome/>
      <Footer showBothDivs={true}  textColor="white"/>
    </Layout>
  )
}

export default HomePage