import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import "./Login.css";
import logo from "../../Assets/logo.png";
import { FaTimes } from "react-icons/fa"; 

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendTime, setResendTime] = useState(20);
  const [cartItems, setCartItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleOtpChange = (index, value) => {
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSendOtp = () => {
    if (mobile.length === 10) {
      setIsOtpSent(true);
      setResendTime(20);
      setIsModalOpen(true);
    }
  };

  const handleResendOtp = () => {
    if (resendTime === 0) {
      handleSendOtp();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitOtp = () => {
    console.log("OTP Submitted:", otp.join(""));
    handleCloseModal();
  };

  useEffect(() => {
    if (isOtpSent && resendTime > 0) {
      const timer = setTimeout(() => setResendTime(resendTime - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTime, isOtpSent]);

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isFooter={true}
      isNavbar={true}
    >
      <div className="login-top-section"></div>
      <div className="login-container">
        <div className="left-side">
          <img src={logo} alt="Logo" />
          <h2>Log in</h2>
        </div>
        <div className="right-side">
          <h2>{isOtpSent ? "Enter OTP" : "Log in"}</h2>
          <p>Enter your Phone Number and we'll send you a login code</p>
          <div className="input-containerr">
            <input
              type="tel"
              value={mobile}
              onChange={handleMobileChange}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault(); 
                }
              }}
              placeholder="Enter Your Mobile Number"
              className="mobile-input"
              maxLength="10"
              pattern="[0-9]*"
            />
            <button
              className="COntinue-btn"
              onClick={handleSendOtp}
              disabled={mobile.length !== 10 || isOtpSent}
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="otp-modal">
          <div className="otp-modal-content">
            <FaTimes className="close-iconn" onClick={handleCloseModal} />{" "}
            {/* Close icon */}
            <h2>Enter OTP</h2>
            <div className="otp-container">
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  maxLength="1"
                  className="otp-input"
                />
              ))}
            </div>
            <div className="otp-actions">
              {resendTime > 0 ? (
                <span>Resend OTP in: {resendTime} seconds</span>
              ) : (
                <span className="resend-linkk" onClick={handleResendOtp}>
                  Resend OTP
                </span>
              )}
              <button className="submit-otp-btnn" onClick={handleSubmitOtp}>
                Submit OTP
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Login;
