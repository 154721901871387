import React, { useState, useEffect } from "react";
import Layout from "./../Components/Layout/Layout";
import giftbox from "../Assets/giftbox.png";
import "../Styles/CartStyles.css";
import img4 from "../Assets/gift-wrap.png";
import { FaPlus, FaMinus } from "react-icons/fa6";
import axios from "axios";
import toast from "react-hot-toast";
import Footer from "../Components/Layout/Footer";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const accessToken = "1";
  const defaultImage =
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg";

  useEffect(() => {
    fetchCartItems();

    const storedPromoCode = localStorage.getItem("promoCode");
    const storedIsPromoApplied =
      localStorage.getItem("isPromoApplied") === "true";
    const storedDiscount = parseFloat(localStorage.getItem("discount")) || 0;

    if (storedPromoCode) {
      setPromoCode(storedPromoCode);
      setIsPromoApplied(storedIsPromoApplied);
      setDiscount(storedDiscount);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("promoCode", promoCode);
    localStorage.setItem("isPromoApplied", isPromoApplied);
    localStorage.setItem("discount", discount);
  }, [promoCode, isPromoApplied, discount]);

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "http://tarunika.backendapihub.com/cart/show",
        {
          headers: { "access-token": accessToken },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          const combinedItems = response.data.data.reduce((acc, item) => {
            const key = `${item.product_id}-${item.size_id}`;
            if (!acc[key]) {
              acc[key] = { ...item, qty: 0, amount: 0 };
            }
            acc[key].qty += item.qty;
            acc[key].amount = acc[key].qty * acc[key].price;
            return acc;
          }, {});
  
          setCartItems(Object.values(combinedItems));
        } else {
          console.error("Cart items data is not an array");
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
        setError("Failed to load cart items. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("Error fetching cart items. Please try again later.");
      setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const handleQuantityChange = async (product_id, size_id, change) => {
    const item = cartItems.find((item) => item.product_id === product_id && item.size_id === size_id);
    const newQuantity = item ? item.qty + change : 0;

    if (newQuantity >= 0) {
      try {
        const response = await axios.post(
          "http://tarunika.backendapihub.com/cart/add",
          {
            l1_id: product_id,
            quantity: newQuantity,
            size_id: size_id,
          },
          {
            headers: { "access-token": accessToken },
          }
        );
        if (response.status === 200) {
          setCartItems((prevItems) =>
            prevItems.map((item) =>
              item.product_id === product_id && item.size_id === size_id
                ? {
                    ...item,
                    qty: newQuantity,
                    amount: newQuantity * item.price,
                  }
                : item
            )
          );
        } else {
          toast.error("Failed to update the quantity.");
        }
      } catch (error) {
        console.error("Error updating quantity:", error);
        toast.error("Error updating quantity. Please try again later.");
      }
    }
  };


  const handleRemoveItem = async (id) => {
    confirmAlert({
      title: "Move from Cart",
      message: "Are you sure you want to remove this item from your cart?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.get(
                `http://tarunika.backendapihub.com/cart/delete/${id}`,
                {
                  headers: {
                    "access-token": accessToken,
                  },
                }
              );
              if (response.status === 200) {
                setCartItems((prevItems) =>
                  prevItems.filter((item) => item.id !== id)
                );
                toast.success("Item removed successfully");
              } else {
                console.error("Failed to remove item from cart");
                toast.error("Failed to remove item from cart");
              }
            } catch (error) {
              console.error("Error removing item from cart:", error);
              toast.error("Error removing item from cart");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const applyPromoCode = async () => {
    try {
      const response = await axios.post(
        "http://tarunika.backendapihub.com/cart/applyPromoCode",
        {
          promocode: promoCode,
          amount: calculateSubtotal(),
        },
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        const discountAmount = response.data.data[0].amount;

        if (discountAmount > 0) {
          setDiscount(discountAmount);
          setIsPromoApplied(true);
          toast.success("Promo code applied successfully!");
        } else {
          setDiscount(0);
          setIsPromoApplied(false);
          toast.error("Invalid Promocode");
        }
      } else {
        toast.error("Failed to apply promo code.");
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      toast.error("Error applying promo code. Please try again later.");
    }
  };

  const removePromoCode = () => {
    setPromoCode("");
    setDiscount(0);
    setIsPromoApplied(false);
    localStorage.removeItem("promoCode");
    localStorage.removeItem("discount");
    localStorage.removeItem("isPromoApplied");
    toast.success("Promo code removed successfully!");
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((acc, item) => acc + item.amount, 0);
  };

  const calculateDiscount = () => {
    return discount;
  };

  const calculateTotal = () => {
    return calculateSubtotal() - calculateDiscount();
  };

  const handleCheckout = () => {
    navigate("/checkout", {
      state: {
        cartItems,
        discount,
      },
    });
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      {/* <Navbar showNormalNav={true} cartCount={calculateTotalQuantity()} /> */}
      <div className="cart-banner d-flex justify-content-center align-items-center flex-column">
        <div className="cart-head-title">
          <h1 className="m-0">Your Cart</h1>
        </div>
        {/* <div className="cart-offer d-flex justify-content-center align-items-center">
          <img src={giftbox} alt="" />
          <p className="m-0">
            Diwali Delight: Shop for ₹3,424.00 more to receive a Soundarya
            Ritual Gift Box complimentary on your purchase.
          </p>
        </div> */}
      </div>
      {Array.isArray(cartItems) && cartItems.length === 0 ? (
        <div className="empty-cart-message d-flex justify-content-center align-items-center flex-column">
          <h2>Your cart is empty</h2>
          {/* <p>Add some items to your cart to see them here.</p> */}
          <p>There is nothing in your cart. Let's add some items.</p>
        </div>
      ) : (
        <div className="cart-list-container">
          <div className="cart-list">
            <div className="cart-list-items position-relative">
              <h6 className="m-0 price-head position-absolute">Price</h6>
              <h6 className="m-0 quantity-head position-absolute">Quantity</h6>
              <h6 className="m-0 totla-head position-absolute">Total</h6>
              {Array.isArray(cartItems) &&
                cartItems.map((item) => (
                  <div key={item.id}>
                    <div className="cart-card">
                      <div className="cart-img">
                        <img
                          src={
                            item.image
                              ? `http://tarunika.backendapihub.com/images/${item.image}`
                              : defaultImage
                          }
                          alt="cart-img"
                        />
                      </div>
                      <div className="cart-innerbody">
                        <div className="cart-title-desc">
                          <h3 className="m-0">{item.product_name}</h3>
                          <div
                            className="cart-remove-option"
                            onClick={() => {
                              if (item.id) {
                                handleRemoveItem(item.id);
                              } else {
                                console.error(
                                  "Product ID is undefined or null for item:",
                                  item
                                );
                              }
                            }}
                          >
                            Remove
                          </div>
                        </div>
                        <div className="right-flex">
                          <div className="price">
                            <span>₹ {item.price.toFixed(2)}</span>
                          </div>
                          <div className="quantity">
                            <FaMinus
                              className="minus"
                              onClick={() =>
                                handleQuantityChange(
                                  item.product_id,
                                  item.size_id,
                                  -1
                                )
                              }
                            />
                            <input type="text" value={item.qty} readOnly />
                            <div>
                              <FaPlus
                                className="plus"
                                onClick={() =>
                                  handleQuantityChange(
                                    item.product_id,
                                    item.size_id,
                                    1
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="total">
                            <span>₹ {item.amount.toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="m-0 card-divider" />
                  </div>
                ))}
            </div>
          </div>
          <div className="cart-order-summary">
            <h1 className="summary-title m-0 p-0">Order Summary</h1>
            <hr />
            <div className="calculation">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="m-0 p-0">Subtotal</h3>
                <span className="m-0 p-0">
                  ₹ {calculateSubtotal().toFixed(2)}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="m-0 p-0">Shipping</h3>
                <span
                  className="m-0 p-0"
                  style={{ color: "rgba(13, 158, 0, 1)" }}
                >
                  Free
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="m-0 p-0">Coupon Discount</h3>
                <span className="m-0 p-0">
                  ₹ {calculateDiscount().toFixed(2)}
                </span>
              </div>
            </div>
            <hr />
            <div className="Total d-flex justify-content-between align-items-center">
              <h3 className="m-0 p-0">Total</h3>
              <span className="m-0 p-0">₹ {calculateTotal().toFixed(2)}</span>
            </div>
            <hr />
            <div className="code-wrap">
              <div className="promocode">
                <h3 className="m-0 p-0">Discount promocode</h3>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Enter promocode"
                    disabled={isPromoApplied}
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  {!isPromoApplied ? (
                    <button onClick={applyPromoCode}>Apply</button>
                  ) : (
                    <button
                      onClick={removePromoCode}
                      style={{ padding: "8px 26.9px 9px 26.9px" }}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
              <div className="gift-wrapper">
                <img className="wrapper-img" src={img4} alt="" />
                <div className="wrapper-content">
                  <h3 className="m-0 p-0">Add a gift wrap</h3>
                  <p className="m-0 p-0">
                    A personalised message card & gift wrap are complimentary
                    with your purchase.
                  </p>
                  <button className="m-0 p-0">ADD A GIFT WRAP</button>
                </div>
              </div>
            </div>
            <button className="checkout-btn m-0 p-0" onClick={handleCheckout}>
              CHECKOUT
            </button>
          </div>
        </div>
      )}
      {/* this code for mobile view */}
      <div className="cosp">
        <div className="cart-order-summary-second">
          <h1 className="summary-title m-0 p-0">Order Summary</h1>
          <hr />
          <div className="calculation">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="m-0 p-0">Subtotal</h3>
              <span className="m-0 p-0">
                ₹ {calculateSubtotal().toFixed(2)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="m-0 p-0">Shipping</h3>
              <span
                className="m-0 p-0"
                style={{ color: "rgba(13, 158, 0, 1)" }}
              >
                Free
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="m-0 p-0">Coupon Discount</h3>
              <span className="m-0 p-0">
                ₹ {calculateDiscount().toFixed(2)}
              </span>
            </div>
          </div>
          <hr />
          <div className="Total d-flex justify-content-between align-items-center">
            <h3 className="m-0 p-0">Total</h3>
            <span className="m-0 p-0">₹ {calculateTotal().toFixed(2)}</span>
          </div>
          <hr />
          <div className="code-wrap">
            <div className="promocode">
              <h3 className="m-0 p-0">Discount promocode</h3>
              <div className="position-relative">
                <input
                  type="text"
                  placeholder="Enter promocode"
                  disabled={isPromoApplied}
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                {!isPromoApplied ? (
                  <button onClick={applyPromoCode}>Apply</button>
                ) : (
                  <button onClick={removePromoCode}>Remove </button>
                )}
              </div>
            </div>
            <div className="gift-wrapper">
              <img className="wrapper-img" src={img4} alt="" />
              <div className="wrapper-content">
                <h3 className="m-0 p-0">Add a gift wrap</h3>
                <p className="m-0 p-0">
                  A personalised message card & gift wrap are complimentary with
                  your purchase.
                </p>
                <button className="m-0 p-0">ADD A GIFT WRAP</button>
              </div>
            </div>
          </div>
          <button className="checkout-btn m-0 p-0">CHECKOUT</button>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default CartPage;
