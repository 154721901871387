import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { useWishlist } from '../Context/WishlistContext';
import './Item.css';
import axios from 'axios';

const defaultImage = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg';

export const Item = ({ id, name, desc, img, price, l1, product_id }) => {
  const [wishlistClicked, setWishlistClicked] = useState(false);
  const navigate = useNavigate();
  const { wishlist, removeFromWishlist, addToWishlist } = useWishlist();

  useEffect(() => {
    setWishlistClicked(wishlist.includes(product_id));
  }, [wishlist, product_id]);

  const toggleWishlist = async () => {
    try {
      if (wishlistClicked) {
        // Remove from wishlist
        await axios.delete('http://tarunika.backendapihub.com/wishlist/wishlist/remove', {
          headers: {
            'access-token': '1',
            'Content-Type': 'application/json',
          },
          data: { productID: product_id },
        });

        removeFromWishlist(product_id);
        setWishlistClicked(false);
        toast.success('Item removed from wishlist.');
      } else {
        // Add to wishlist
        await axios.post('http://tarunika.backendapihub.com/wishlist/wishlist/add', { productvariationID: product_id }, {
          headers: {
            'access-token': '1',
          },
        });

        addToWishlist(product_id);
        setWishlistClicked(true);
        toast.success('Item added to wishlist.');
      }
    } catch (error) {
      toast.error('Error updating wishlist.');
    }
  };

  const goToDetailsPage = () => {
    navigate(`/product/${l1}`);
  };

  return (
    <div className="card">
      <div className="card__thumbnail">
        <img src={img} alt={name} onClick={goToDetailsPage} onError={(e) => e.target.src = defaultImage} />
        <div className="icons-container">
          <FontAwesomeIcon
            icon={wishlistClicked ? fasHeart : farHeart}
            className={`wishlist-icon ${wishlistClicked ? 'clicked' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleWishlist();
            }}
          />
          <FontAwesomeIcon
            icon={faShareAlt}
            className="share-alt-icon"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
      <div className="card__body">
        <h5 className="card__title" onClick={goToDetailsPage}>{name}</h5>
        <p className="card__desc" onClick={goToDetailsPage}>{desc}</p>
        <p className="card__price" onClick={goToDetailsPage}>₹ {price}</p>
        <button className="card__button" onClick={goToDetailsPage}>View Details</button>
      </div>
    </div>
  );
};

export default Item;
