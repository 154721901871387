import React, { useEffect, useState, useRef } from "react";
import "../Styles/ProductItem.css";
import Layout from "../Components/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axios from "axios";
import Footer from "../Components/Layout/Footer";
import { useParams } from "react-router-dom";
import ViewSimilar from "../Components/Sub_Home/ViewSimilar";

export const ProductItem1 = () => {
  const { slug: id } = useParams();
  const [cartItems, setCartItems] = useState([]);
  const [productData, setProductData] = useState(null);
  const [wishlistClicked, setWishlistClicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [pincode, setPincode] = useState("");
  const [message, setMessage] = useState("");
  const [apiFailed, setApiFailed] = useState(false);

  const defaultImage =
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg";

  const toggleWishlist = () => {
    setWishlistClicked(!wishlistClicked);
  };

  const accessToken = "1";
  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "http://tarunika.backendapihub.com/cart/show",
        {
          headers: { "access-token": accessToken },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems(response.data.data);
        } else {
          console.error("Cart items data is not an array");
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  useEffect(() => {
    if (!id || isNaN(id)) {
      console.error("Invalid id:", id);
      return;
    }
    axios
      .get(`http://tarunika.backendapihub.com/product/detailview/${id}`)
      .then((response) => {
        setProductData(response.data);
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const product = response.data.data[0];
          if (product.media && product.media.length > 0) {
            setSelectedImage(product.media[0]);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiFailed(true);
      });
  }, [id]);

  useEffect(() => {
    console.log("Selected image:", selectedImage);
  }, [selectedImage]);

  const handleCheckPincode = async () => {
    if (pincode.length !== 6 || isNaN(pincode)) {
      setMessage("Please enter a valid 6-digit pincode.");
      return;
    }

    try {
      const response = await fetch(
        `http://tarunika.backendapihub.com/address/getPincode?pincode=${pincode}`,
        {
          method: "GET",
          headers: { accept: "application/json" },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (
          data.status.toLowerCase() === "success" &&
          data.message === "Pincode found"
        ) {
          setMessage("Delivery available for this pincode");
        } else {
          setMessage("Unfortunately we do not ship to your pincode");
        }
      } else {
        setMessage("Unfortunately we do not ship to your pincode");
      }
    } catch (error) {
      console.error("Error fetching pincode:", error);
      setMessage("Error: Unable to fetch data.");
    }
  };

  useEffect(() => {
    const stars = document.querySelectorAll(".star");

    stars.forEach((star, index) => {
      star.addEventListener("click", function () {
        resetStars();
        for (let i = 0; i <= index; i++) {
          stars[i].classList.add("active");
        }
      });
    });

    function resetStars() {
      stars.forEach((star) => {
        star.classList.remove("active");
      });
    }

    return () => {
      stars.forEach((star) => {
        star.removeEventListener("click", resetStars);
      });
    };
  }, []);

  function hideAllExcept(idToKeepVisible) {
    let buttons = document.querySelectorAll(".opendes, .openpro, .openkey");
    // console.log(buttons);

    buttons.forEach((button) => {
      let ids = button.id;
      console.log(ids);
      if (ids === idToKeepVisible) {
        // console.log("active added");
        button.classList.add("active");
      } else {
        // console.log("nothing");
        button.classList.remove("active");
      }
    });

    var divs = document.querySelectorAll(".desc, .prof, .key");

    divs.forEach(function (div) {
      if (div.id === idToKeepVisible) {
        div.classList.remove("d-none");
      } else {
        div.classList.add("d-none");
      }
    });
  }

  function openDes() {
    hideAllExcept("des");
  }

  function openPro() {
    hideAllExcept("pro");
  }

  function openKey() {
    hideAllExcept("key");
  }

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsPlaying(false);
      }
    };

    const handleClickOutside = (event) => {
      if (!event.target.closest(".tes-triangle")) {
        setIsPlaying(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const addToCart = () => {
    if (!selectedSize || !selectedColor) {
      toast.error("Please select both size and color.");
      return;
    }

    const data = {
      l1_id: id,
      quantity: 1,
      size_id: selectedSize.size_id,
      color_id: selectedColor.id, // Include selected color in the data
    };
    axios
      .post("http://tarunika.backendapihub.com/cart/add", data, {
        headers: { "access-token": accessToken },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          fetchCartItems();
        } else {
          toast.error("Failed to add item to cart.");
        }
      })
      .catch((error) => {
        toast.error("Error adding item to cart.");
      });
  };

  const handleColorSelect = (color) => {
    console.log("Selected color:", color);
    setSelectedColor(color);  // Save the entire color object
  };  

  if (!productData) {
    if (apiFailed) {
      return (
        <Layout>
          <div className="ProductItem p-5">
            <div className="row m-0">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <img src={defaultImage} alt="No images available" />
              </div>
              <div className="col-lg-8 mb-3 mb-lg-0">
                <p>
                  Product details are currently unavailable. please try again
                  later.{" "}
                </p>
              </div>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <div className="ProductItem p-5">
            <div className="row m-0">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <img src={defaultImage} alt="Loading image" />
              </div>
              <div className="col-lg-8 mb-3 mb-lg-0">
                <p>Loading image...</p>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }

  const { category_name, product_name, sku, media, size, colour, description } =
    productData.data[0];
  // console.log("cartItems in ProductItem1:", cartItems);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="item-top-section"></div>
      <div className="deatils-layer"></div>
      <div className="ProductItem">
        <div className="row m-0">
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="product-img">
              {media && media.length > 0 ? (
                media.map((imgSrc, index) => (
                  <img
                    key={index}
                    src={`http://tarunika.backendapihub.com/images/${imgSrc}`}
                    alt={`product-img-${index}`}
                    onClick={() => setSelectedImage(imgSrc)}
                    onMouseEnter={() => setSelectedImage(imgSrc)}
                  />
                ))
              ) : (
                <img src={defaultImage} alt="No images available" />
              )}
            </div>
          </div>

          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="small-one rgtprd d-flex flex-column align-items-center p-2">
              {selectedImage ? (
                <img
                  className="test img-fluid w-100"
                  src={`http://tarunika.backendapihub.com/images/${selectedImage}`}
                  alt=""
                />
              ) : (
                <img src={defaultImage} alt="Loading image" />
              )}

              <div className="icons-container">
                <FontAwesomeIcon
                  icon={wishlistClicked ? fasHeart : farHeart}
                  className={`wishlist-icon ${
                    wishlistClicked ? "clicked" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleWishlist();
                  }}
                />
                <FontAwesomeIcon
                  icon={faShareAlt}
                  className="share-alt-icon"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              <div className="new-layer"></div>
            </div>
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="small-none py-4" id="jkjk">
              <div className="content-one">
                <h1>{product_name}</h1>
                <h6>
                  SKU : <span>{sku}</span>
                </h6>
                <p>{description}</p>
              </div>
              <div className="content-two d-flex gap-3 align-items-center my-4">
                <h6 className="m-0">Colour</h6>
                {colour &&
                  colour.map((variation, index) => (
                    <a
                      key={index}
                      className={`item-btn ${
                        selectedColor === variation ? "selected" : ""
                      }`}
                      // onClick={() => {
                      //   console.log("Selected color:", variation);
                      //   setSelectedColor(variation);
                      // }}
                      onClick={() => handleColorSelect(variation)}
                    >
                      {variation.l1_primary_filter}
                    </a>
                  ))}
              </div>
              <div className="content-two d-flex gap-3 align-items-center my-4">
                <h6 className="m-0">Size</h6>
                {size &&
                  size.map((variation, index) => (
                    <a
                      key={index}
                      className={`item-btnn ${
                        selectedSize === variation ? "selected" : ""
                      }`}
                      onClick={() => {
                        console.log("Selected size:", variation);
                        setSelectedSize(variation);
                      }}
                    >
                      {variation.size_name}
                    </a>
                  ))}
              </div>
              {/* <div className="content-two d-flex gap-3 align-items-center my-4">
                <h6 className="m-0">Colour</h6>
                {colour &&
                  colour.map((variation, index) => (
                    <a key={index} className="item-btn">
                      {variation.col_id}
                    </a>
                  ))}
              </div> */}
              {/* <div className="quantity">
                <FaMinus className="minus" />
                <input type="text" readOnly />
                <FaPlus className="plus" />
              </div> */}
              <div className="content-three d-flex flex-column my-4" id="sdcp">
                <span>₹ {size && size.length > 0 && size[0].price}</span>
                <p className="m-0">(MRP INCLUSIVE OF ALL TAXES)</p>
              </div>

              <div className="pincode-input mb-4">
                <label htmlFor="pincode">Delivery Options</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Enter Pincode"
                    id="pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="form-control"
                    maxLength="6"
                  />
                  <button
                    type="button"
                    className="check"
                    onClick={handleCheckPincode}
                  >
                    Check
                  </button>
                </div>
                {message && (
                  <p
                    style={{
                      color:
                        message === "Delivery available for this pincode"
                          ? "#3c0202"
                          : "#FF0000",
                    }}
                  >
                    {message}
                  </p>
                )}
              </div>

              <div className="d-flex flex-column gap-2 my-5">
                <a className="purchase-btn cart-btn" onClick={addToCart}>
                  ADD TO CART
                </a>
                <a className="purchase-btn buy-btn">BUY NOW</a>
              </div>
              <div>
                <div className="content-four d-flex justify-content-start gap-4 align-items-center">
                  <h6 id="des" className="opendes m-0" onClick={openDes}>
                    Product Details
                  </h6>
                  <h6 id="pro" className="openpro m-0" onClick={openPro}>
                    Material & Care
                  </h6>
                  <h6 id="key" className="openkey m-0" onClick={openKey}>
                    Specifications
                  </h6>
                </div>
                <hr />
                <div id="des" className="desc">
                  <p className="para">
                    Offering a classic and ornamental look, this delicate cream
                    kurta set showcases a refined bel buti pattern. The kurta
                    features a potli buttoned half placket and an embroidered
                    bandhgala style, enhancing its traditional elegance. Perfect
                    for cultural festivities or casual gatherings, style this
                    set with traditional mojaris. A gold bracelet would add to
                    its regal charm.
                  </p>
                  <p className="para">
                    {/* Our Body Mists feature a unique blend of scented essential
                    oils and fresh Aloe Vera juice to create fragrances that not
                    only delight your senses but also leave your skin hydrated
                    for long hours. */}
                  </p>
                </div>
                <div id="pro" className="prof d-none">
                  <p className="para">
                    This kurta is crafted from premium-quality fabric, ensuring
                    both comfort and durability. To maintain its vibrant color
                    and texture, hand wash with mild detergent and air dry in
                    shade.
                  </p>
                </div>
                <div id="key" className="key d-none">
                  <p className="para">
                    {/* Our Body Mists feature a unique blend of scented essential
                    oils and fresh Aloe Vera juice to create fragrances that not
                    only delight your senses but also leave your skin hydrated
                    for long hours. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <ViewSimilar />
      <Footer showBothDivs={false} />
    </Layout>
  );
};
export default ProductItem1;
