import React from 'react'
import Hero from './Hero'
import CategorySection from './CategorySection'
import NewArrivals from './NewArrivals'
import ShopByCategory from './ShopByCategory'
import TradingCategories from './TradingCategories'
import FestivalKurtas from './FestivalKurtas'

const SubHome = () => {
  return (
    <div>
        <Hero/>
       <CategorySection/>
       <ShopByCategory/>
       <TradingCategories/>
       <FestivalKurtas/>
       {/* <NewArrivals/> */}
        {/* <About showAboutMainpg={true} /> */}
        {/* <BestSellings/> */}
        {/* <Freebeauty/> */}
        {/* <Categories/> */}
        {/* <Handicrafts/> */}
        {/* <Collection/> */}
        {/* <TissueBanner/> */}
        {/* <Testimonial/> */}
        {/* <Footer showBothDivs={true} /> */}
    </div>
  )
}

export default SubHome