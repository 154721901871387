import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import "../Styles/AboutPage.css";
import Footer from "../Components/Layout/Footer";


const AboutPage = () => {
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems}  isNavbar={true} >
      {/* <Footer showBothDivs={false} /> */}
    </Layout>
  );
};

export default AboutPage;
