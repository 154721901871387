import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import Footer from "./Footer";

const Layout = ({
  children,
  title,
  description,
  keywords,
  author,
  cartItems,
  setCartItems,
  isFooter,
  isNavbar,
  textColor
}) => {
  console.log("cartItemsgrgreger", cartItems);

  const calculateTotalQuantity = () => {
    return cartItems?.reduce((total, item) => total + item?.qty, 0);
  };

  const accessToken = "1";

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "http://tarunika.backendapihub.com/cart/show",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      // console.log("cartItemsgrgreger response.data.data", response.data.data);
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems(response.data.data);
        } else {
          console.error("Cart items data is not an array:", response.data.data);
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems && setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  return (
    <div>
      {/* ...SEO friendly...*/}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>

      {isNavbar && (
        <Navbar showNormalNav={true} cartCount={calculateTotalQuantity()} textColor={textColor} />
      )}
      <main >
        <Toaster />

        {children}
        {isFooter && <Footer showBothDivs={true} />}
      </main>
    </div>
  );
};

Layout.defaultProps = {
  title: "Tarunika",
  description: "Sandalwood-Har Ghar Chandan",
  keywords: "facial-care, body-care, hair-care",
  author: "@Sandalwood",
  textColor: "black"
};

export default Layout;
