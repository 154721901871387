import React from "react";
import "./Hero.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import hero1 from "../../Assets/hero-img1.webp";
import hero2 from "../../Assets/hero-img2.jpg";
import hero3 from "../../Assets/hero-img3.webp";
import hero4 from "../../Assets/hero-img4.webp";

const Hero = () => {
  return (
    <div className="hero">
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000} 
        transitionTime={500} 
      >
        <div className="hero-slide">
          <img src={hero1} alt="Hero 1" />
        </div>
        <div className="hero-slide">
          <img src={hero2} alt="Hero 2" />
        </div>
        <div className="hero-slide">
          <img src={hero3} alt="Hero 3" />
        </div>
        <div className="hero-slide">
          <img src={hero4} alt="Hero 4" />
        </div>
      </Carousel>
    </div>
  );
};

export default Hero;
